import React from 'react'

import { PartnerProduct } from '@vp/digital-paid-lib-ubik'
import { WIX_HOME_URL } from '@vp/digital-url-service-lib-ubik'
import {
  Box,
  Callout,
  FlexBox,
  FluidImage,
  SelectionSetInput,
  SelectionSetLabel,
  Span,
  Typography,
  useScreenClass,
} from '@vp/swan'

interface PageContextProps {
  pageContext: any;
}

interface WixSitesProps extends PageContextProps {
  site: PartnerProduct;
}
const WixSite = ({ pageContext, site }: WixSitesProps) => {
  const { notPublishedTextBlock, premiumTextBlock } = pageContext.pageData

  const getDomainPlaceHolder = (product: PartnerProduct): string => {
    try {
      return product.published
        ? new URL(product?.viewUrl).hostname
        : notPublishedTextBlock.title
    } catch (error) {
      return notPublishedTextBlock.title
    }
  }

  const isMobile = useScreenClass() === 'xs'

  return (
    <SelectionSetInput value={site.id}>
      <SelectionSetLabel>
        <FlexBox justifyContent='space-between' gap='5'>
          <FlexBox alignItems='center' gap='5'>
            <FluidImage
              src={`${WIX_HOME_URL + site.thumbnail}`}
              alt={site.displayName ?? ''}
              style={{ width: isMobile ? '80px' : '115px' }}
            />
            <Box>
              <Span fontSize='standard'>{site?.displayName}</Span>

              <Typography fontSize='small' mt='3' textColor='subtle'>
                {getDomainPlaceHolder(site)}
              </Typography>
            </Box>
          </FlexBox>

          <Box>
            {site?.premium && <Callout>{premiumTextBlock.title}</Callout>}
          </Box>
        </FlexBox>
      </SelectionSetLabel>
    </SelectionSetInput>
  )
}

export default WixSite
