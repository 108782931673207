const emptyArray: any[] = []
export const siteListResponse = {
  wixSites: [
    {
      id: 'a1c10e1a-a05f-48d4-a899-2123a57a4f14',
      htmlAppId: 'fc489a10-69bf-4840-a87e-7153b36fc376',
      name: 'qaparintsitetrasnfer',
      displayName: 'Qaparintsitetrasnfer',
      createdDate: '2023-02-27T19:52:10.414+00:00',
      published: true,
      premium: false,
      viewUrl: 'https://testqatj.wixsite.com/qaparintsitetrasnfer',
      editUrl:
        '/editor/a1c10e1a-a05f-48d4-a899-2123a57a4f14?editorSessionId=3fe0c980-b3e4-4e3a-b6de-a4222e1386d8',
      thumbnail: '/site-thumbnail/a1c10e1a-a05f-48d4-a899-2123a57a4f14',
      ownerAccountId: '2e197d9f-d549-425e-87c1-dad090b72635',
      contributorAccountIds: emptyArray,
      editorType: 'EDITOR',
      blocked: false,
      namespace: 'WIX',
      domainConnected: true,
    },
    {
      id: '1bae6af3-b7bb-43b2-80e7-0309ba0554d9',
      htmlAppId: null,
      name: 'my-site-2',
      displayName: 'TechTech',
      createdDate: '2023-01-27T07:49:19.595+00:00',
      published: false,
      premium: false,
      viewUrl: '',
      editUrl:
        '/editor/1bae6af3-b7bb-43b2-80e7-0309ba0554d9?editorSessionId=bbf7b1de-b0fb-4cce-a854-f500f610cc6f',
      thumbnail: '/site-thumbnail/1bae6af3-b7bb-43b2-80e7-0309ba0554d9',
      ownerAccountId: '2e197d9f-d549-425e-87c1-dad090b72635',
      contributorAccountIds: emptyArray,
      editorType: 'UNKNOWN',
      blocked: false,
      namespace: 'DASHBOARD_FIRST',
      domainConnected: false,
    },
    {
      id: 'e4563fe8-4874-4e1a-8350-3efc284b2a69',
      htmlAppId: 'efcba578-6a26-477a-8c9f-829cd90b1d98',
      name: 'cptest3',
      displayName: 'Cptest3',
      createdDate: '2022-09-27T14:01:04.613+00:00',
      published: true,
      premium: true,
      viewUrl: 'https://testqatj.wixsite.com/cptest3',
      editUrl:
        '/editor/e4563fe8-4874-4e1a-8350-3efc284b2a69?editorSessionId=11eed241-e5df-40de-b4e0-1c4caa6986ad',
      thumbnail: '/site-thumbnail/e4563fe8-4874-4e1a-8350-3efc284b2a69',
      ownerAccountId: '2e197d9f-d549-425e-87c1-dad090b72635',
      contributorAccountIds: emptyArray,
      editorType: 'EDITOR',
      blocked: false,
      namespace: 'WIX',
      domainConnected: true,
    },
    {
      id: '36fa9dd1-d2ee-4ff8-aa75-0d5c474d48a2',
      htmlAppId: '0ceeb07a-cb45-48c3-9bf2-2d9f2a87542b',
      name: 'my-site',
      displayName: 'My Site',
      createdDate: '2022-07-28T07:02:17.382+00:00',
      published: false,
      premium: true,
      viewUrl: '',
      editUrl:
        '/editor/36fa9dd1-d2ee-4ff8-aa75-0d5c474d48a2?editorSessionId=d8e7f24a-fdff-4470-87d4-563a7253299a',
      thumbnail: '/site-thumbnail/36fa9dd1-d2ee-4ff8-aa75-0d5c474d48a2',
      ownerAccountId: '2e197d9f-d549-425e-87c1-dad090b72635',
      contributorAccountIds: emptyArray,
      editorType: 'EDITOR',
      blocked: false,
      namespace: 'WIX',
      domainConnected: false,
    },
  ],
}
