import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { SiteSelectorApp } from '../web/site-selector/SiteSelectorApp'

export const Fragment = (props: any) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)
  useSwanStyleKeys(['core'])

  return (
    <SiteSelectorApp {...props} />
  )
}
