// Analytics related to the Wix Domains Site Selector page
import {
  analyticsDataRegistry,
  trackNavigationEvent,
  trackPageViewed,
} from '@vp/digital-tracking-lib-ubik'
const NAVIGATION_CLICKED_ACTION = 'Navigation Clicked'
export const DOMAINS_SITE_SELECTOR_MAIN_HERO_LABEL = 'Content:Main Hero'
export const DOMAINS_SITE_SELECTOR_MAIN_HERO_PAGE_ZONE = 'Main Hero'
export const CONTINUE_OR_TRANSFER_WITHOUT_CONNECTING_SITE_CTA_DETAIL =
  'Get a Domain without connecting it to site'
export const CONTINUE_CTA_DETAIL = 'Continue'

const { wixDomainsSiteSelection } = analyticsDataRegistry
export const trackingAssetName = wixDomainsSiteSelection.trackingAssetName

// Fires a Site seclector page load event,
export function trackWixDomainsSiteSelectorPageLoad (): void {
  trackPageViewed(wixDomainsSiteSelection)
}

export function trackContinueCTAClick (destinationUrl: string) {
  const wixDestinationDomains = {
    pageUri: destinationUrl.split('?')[0],
  }
  // Navigates to wix domains page
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    DOMAINS_SITE_SELECTOR_MAIN_HERO_LABEL,
    wixDomainsSiteSelection, // source
    wixDestinationDomains, // target
    DOMAINS_SITE_SELECTOR_MAIN_HERO_PAGE_ZONE,
    CONTINUE_CTA_DETAIL
  )
}
export function trackContinueOrTransferWithoutConnectingSiteCTAClick (
  destinationUrl: string
) {
  const wixDestinationDomains = {
    pageUri: destinationUrl.split('?')[0],
  }
  // Navigates to wix domains page
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    DOMAINS_SITE_SELECTOR_MAIN_HERO_LABEL,
    wixDomainsSiteSelection, // source
    wixDestinationDomains, // target
    DOMAINS_SITE_SELECTOR_MAIN_HERO_PAGE_ZONE,
    CONTINUE_OR_TRANSFER_WITHOUT_CONNECTING_SITE_CTA_DETAIL
  )
}
