import React, { useEffect, useState } from 'react'
import { analyticsDataRegistry, } from '@vp/digital-tracking-lib-ubik'
import {
  BoundedContent,
  Box,
  Button,
  Column,
  GridContainer,
  H1,
  isNotProduction,
  Link,
  P,
  Row,
  SelectionSet,
  Spinner,
} from '@vp/swan'
import { SEOContainer } from '@vp/digital-seo-lib-ubik'
import { CANONICAL_ID, } from '@vp/digital-auth-lib-ubik'
import { getPartnerAccountProducts, PartnerProduct, } from '@vp/digital-paid-lib-ubik'
import { useHasOwnDomainValue, useSearchedDomainValue, } from '@vp/digital-search-component-lib-ubik'
import { TokenReplacer } from '@vp/digital-token-replacer-lib-ubik'

import {
  CONTINUE_CTA_DETAIL,
  CONTINUE_OR_TRANSFER_WITHOUT_CONNECTING_SITE_CTA_DETAIL,
  DOMAINS_SITE_SELECTOR_MAIN_HERO_LABEL,
  trackContinueCTAClick,
  trackContinueOrTransferWithoutConnectingSiteCTAClick,
} from './WixDomainsSiteSelectorAnalytics'
import Redirect from '../../common/Redirect'
import { siteListResponse } from './WixDomainsSiteSelectorTestData'
import { customerTypeEnum } from '../../common/enum/customerType'
import BackButton from '../../common/backButton/BackButton'
import './wixDomainsSiteSelectorPage.scss'
import { checkIsVistaBillingEnabled } from '../../common/helpers/domainReleaseVersionCheck'
import { urlParamsEnum } from '../../common/enum/urlParamsEnum'
import {
  getPartnerUrls,
  getWixPartnerConnectDomainBuildWebsiteUrl,
  getWixPartnerReviewAndBuyDomainUrl
} from '../../common/helpers/wixDomainsUrls'
import { PartnerUrls, VPAuthIdentity } from '../../common/types/Types'
import { sourceEnum } from '../../common/enum/sourceEnum'
import { experimentKeyEnum } from '../../common/enum/experimentKey'
import { initializeAndFireStatsigImpression } from '../../common/helpers/domainsExperimentCheck'
import WixSite from './components/WixSite'

import styles, { stylesheet } from './wixDomainsSiteSelectorPage.scss'
import { useSignedInAction } from '../../common/helpers/useSignedInAction'
import { useStyles } from '@vp/ubik-context'

type NavigationInfo = {
  continueUrl: string;
  linkUrl: string;
}

const WixDomainsSiteSelectorPage = (pageContext : any) => {
  useStyles(stylesheet)

  const { hero, seo, backCtaTextBlock } = pageContext.pageData
  const {
    locale,
    commonLocalizedUrls: { wixWebsitesUpsellUrlLocalized },
  } = pageContext

  const [productList, setProductList] = useState(null)
  const [selectedSite, setSelectedSite] = useState(null)
  const [isPremium, setIsPremium] = useState(false)

  const [redirectPath, setRedirectPath] = useState(null)
  const [isRedirecting, setIsRedirecting] = useState(false)

  const isVistaBilled = checkIsVistaBillingEnabled(locale)

  const fetchSiteList = async (locale: string, identity: VPAuthIdentity) => {
    const data = await getPartnerAccountProducts(
      identity.profile[CANONICAL_ID] as string
    )
    setProductList(data)
  }

  const [, , initiateFetchSiteList] = useSignedInAction<string, void>(
    fetchSiteList,
    'fetch-site-list-after-sign-in'
  )

  const [domain] = useSearchedDomainValue()

  const { wixDomainsSiteSelection } = analyticsDataRegistry
  let withTestData = false
  const [hasOwnDomainValue] = useHasOwnDomainValue()
  let hasOwnDomain: boolean = hasOwnDomainValue ?? false
  let source: string = sourceEnum.DOMAINS_SITE_SELECTOR
  let domainName: string = domain

  if (typeof window !== 'undefined' && window.location?.search) {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    )
    withTestData =
      urlParams?.get(urlParamsEnum.WITH_TEST_DATA)?.toLowerCase() === 'true'
    hasOwnDomain = urlParams?.has(urlParamsEnum.HAS_OWN_DOMAIN)
      ? urlParams?.get(urlParamsEnum.HAS_OWN_DOMAIN)?.toLowerCase() === 'true'
      : hasOwnDomainValue
    source =
      urlParams?.get(urlParamsEnum.SOURCE)?.toLowerCase() ??
      sourceEnum.DOMAINS_SITE_SELECTOR
    domainName =
      urlParams?.get(urlParamsEnum.DOMAIN_NAME)?.toLowerCase() ?? domain
  }

  const partnerUrls: PartnerUrls = getPartnerUrls(
    source,
    locale,
    domainName,
    selectedSite,
    isPremium,
    source
  )

  const wixPartnerReviewAndBuyDomainsUrl = getWixPartnerReviewAndBuyDomainUrl(
    locale,
    sourceEnum.DOMAINS_SITE_SELECTOR,
    domainName,
    source,
    selectedSite,
    hasOwnDomain
  )

  const wixPartnerConnectDomainsUrl = getWixPartnerConnectDomainBuildWebsiteUrl(
    locale,
    sourceEnum.DOMAINS_SITE_SELECTOR,
    domainName,
    source,
    selectedSite,
    hasOwnDomain
  )

  useEffect(() => {
    if (withTestData && isNotProduction()) setProductList(siteListResponse)
    else initiateFetchSiteList(locale)
  }, [locale, initiateFetchSiteList, withTestData])

  // init tracking
  useEffect(() => {
    // Fire experiment viewed tracking for MIR from Domain Search Pricing Experiment
    initializeAndFireStatsigImpression(
      experimentKeyEnum.WIX_DOMAINS_SEARCH_PRICING_EXPERIMENT,
      wixDomainsSiteSelection
    )
  }, [pageContext.locale, pageContext.trackingKeys])

  const handleTileClick = (siteId: string) => {
    setSelectedSite(siteId)
    setIsPremium(
      productList.wixSites.find((site: any) => site.id === siteId)?.premium
    )
  }

  const getNavigationInfo = (isPremiumSite: boolean): NavigationInfo => {
    let continueUrl: string
    let linkUrl: string
    const { url: websitesUpsellUrl } = wixWebsitesUpsellUrlLocalized

    if (hasOwnDomainValue || hasOwnDomain) {
      continueUrl = wixPartnerConnectDomainsUrl
      linkUrl = partnerUrls.wixPartnerTransferDomainUrl
    } else {
      // Keeping Vista Billing Logic in case we need to get rid of it in future
      if (isVistaBilled) {
        continueUrl = isPremiumSite
          ? partnerUrls.vistaDomainRegistrationUrl // site-specific Vista Domain Registration flow
          : websitesUpsellUrl +
            `?${urlParamsEnum.CUSTOMER_TYPE}=${customerTypeEnum.FREE_USER_WITH_NEW_DOMAIN}&siteId=${selectedSite}`
        linkUrl = partnerUrls.vistaDomainRegistrationUrl // standalone Vista Domain Registration flow
      }
      continueUrl = wixPartnerReviewAndBuyDomainsUrl // site-specific domain purchase
      linkUrl = partnerUrls.wixPartnerPurchaseDomainUrl // standalone domain purchase
    }

    return {
      continueUrl,
      linkUrl,
    }
  }

  function handleContinue () {
    const updatedNavigationData = getNavigationInfo(isPremium)
    setIsRedirecting(true)
    trackContinueCTAClick(updatedNavigationData.continueUrl)
    setRedirectPath(updatedNavigationData.continueUrl)
  }

  function handleContinueOrTransferWithoutConnectingSite () {
    setSelectedSite(undefined)
    setIsPremium(false)
    const updatedNavigationData = getNavigationInfo(isPremium)
    setIsRedirecting(true)
    trackContinueOrTransferWithoutConnectingSiteCTAClick(
      updatedNavigationData.linkUrl
    )
    setRedirectPath(updatedNavigationData.linkUrl)
  }

  return (
    <Redirect redirectPath={redirectPath} isRedirecting={isRedirecting}>
      <>
        <SEOContainer content={seo} />

        {hero && (
          <BoundedContent>
            <BackButton text={backCtaTextBlock.title} />
            <H1 m={0} mt={8} fontWeight='normal'>
              <TokenReplacer
                template={hero.descriptionLong}
                replacements={{
                  domainName: domainName ?? hero?.footer,
                }}
                convertMarkdown
              />
            </H1>

            {!productList && <Spinner accessibleText='Loading...' mt={8} />}

            <SelectionSet
              mt={8}
              onSelectedValueChange={(siteId) => handleTileClick(siteId)}
            >
              <GridContainer>
                <Row>
                  {productList &&
                    productList?.wixSites?.length > 0 &&
                    productList.wixSites.map((site: PartnerProduct) => (
                      <Column
                        span={12}
                        spanSm={6}
                        spanMd={5}
                        spanLg={5}
                        spanXl={5}
                        key={site.id}
                      >
                        <WixSite site={site} pageContext={pageContext} />
                      </Column>
                    ))}
                </Row>
              </GridContainer>
            </SelectionSet>

            <Box
              mt='7'
              mb={8}
              className={styles.wixDomainsSiteSelectorCtaButtons}
            >
              <Button
                skin='primary'
                disabled={!selectedSite}
                onClick={() => handleContinue()}
                data-section={DOMAINS_SITE_SELECTOR_MAIN_HERO_LABEL}
                data-position='1'
                data-translation={CONTINUE_CTA_DETAIL}
              >
                {hero.ctaName}
              </Button>
              <P>{hero.description}</P>
              <Link
                fontSize='small'
                onClick={() => handleContinueOrTransferWithoutConnectingSite()}
                data-section={DOMAINS_SITE_SELECTOR_MAIN_HERO_LABEL}
                data-position='2'
                data-translation={
                  CONTINUE_OR_TRANSFER_WITHOUT_CONNECTING_SITE_CTA_DETAIL
                }
              >
                {hero?.tertiaryCtaName}
              </Link>
            </Box>
          </BoundedContent>
        )}
      </>
    </Redirect>
  )
}

export default WixDomainsSiteSelectorPage
